import React, { Component } from 'react';
import axios from 'axios';
import NumberFormat from 'react-number-format';

export class Bara extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            currentStep: 1,
            flatarmal:  20,
            halli: '',
            uppstolad: '',
            efnibara: '',
            efni: '',
            einangruntegund: '',
            thakkant:'',
            thakkantur_timbur: '',
            thakkantur_al: '',
            v_uppstolad: '',
            litad_al_20: '',
            litad_al_10_20: '',
            alozink_20: '',
            alozink_10_20: '',
            drendukur_torf: '',
            utskyring_1: '',
            utskyring_2: '',
            utskyring_3: '',
            utskyring_4: '',
            utskyring_5: '',
            utskyring_6: '',
            utskyring_7: '',
            utskyring_8: '',
            bordklaedning: '',
            verkbyrjun:'', 
            v_bordklaedning:'',
        }
    }

    componentDidMount () { 
        axios.get('https://content.thakco.is/wp-json/wp/v2/pages/139')
        .then(res => this.setState({
            thakkantur_timbur : res.data.acf.thakkantur_timbur, 
            thakkantur_al: res.data.acf.thakkantur_al, 
            v_uppstolad: res.data.acf.uppstolad, 
            litad_al_20: res.data.acf.litad_al_20, 
            litad_al_10_20: res.data.acf.litad_al_10_20, 
            alozink_20: res.data.acf.alozink_20, 
            alozink_10_20: res.data.acf.alozink_10_20, 
            utskyring_1: res.data.acf.utskyringu_1,
            utskyring_2: res.data.acf.utskyringu_2,
            utskyring_3: res.data.acf.utskyringu_3,
            utskyring_4: res.data.acf.utskyringu_4,
            utskyring_5: res.data.acf.utskyringu_5,
            utskyring_6: res.data.acf.utskyringu_6,
            utskyring_7: res.data.acf.utskyringu_7,
            utskyring_8: res.data.acf.utskyringu_8,
            verkbyrjun: res.data.acf.verkbyrjun,
            v_bordklaedning: res.data.acf.bordklaedning,
        }))
        .catch(err => console.log(err))
    }

    handleChange = event => {
        const {name, value} = event.target
        this.setState({
            [name]: value
        })    
    }

    _fatibod = () => {
        let currentStep = this.state.currentStep
        currentStep = 9
        console.log(currentStep)
        this.setState({
            currentStep: currentStep
        })
    }

    _next = () => {
        let currentStep = this.state.currentStep
        let efnibara = this.state.efnibara;
        let uppstolad = this.state.uppstolad;
        let thakkant = this.state.thakkant;

        currentStep = currentStep >= 9? 10: currentStep + 1
        
        if(currentStep == 7) {
            if (thakkant == "nei") {
                currentStep=8;
            }
        }

        
        this.setState({
            currentStep: currentStep
        })
    }
        
    _prev = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep <= 1? 1: currentStep - 1
        this.setState({
          currentStep: currentStep
        })
    }
    
    previousButton() {
      let currentStep = this.state.currentStep;
      
      if(currentStep !==1){
        return (
          <button className="btn btn-secondary" type="button" onClick={this._prev}>Til baka</button>
        )
      }
      return null;
    }
    
    nextButton() {
        let currentStep = this.state.currentStep;
        if(currentStep < 8){
            return (
                <button className="btn btn-primary float-right" type="button" onClick={this._next}>Áfram</button>        
            )
        }
        return null;
    }

    faformlegttilbodButton() {
        let currentStep = this.state.currentStep;
        if(currentStep == 8){
            return (
                <div>
                <div className="fatilbodtakki"><button className="btn btn-primary fatilbod" type="button" onClick={this._next}>Fá formlegt tilboð</button></div>
                <div className="utskyring"><div dangerouslySetInnerHTML={{__html: this.state.utskyring_8}} /></div> 
                </div>  
            )
        }
        return null;
    }


    render() {    
        return (
            <React.Fragment>
                <div className="wrapperReikn">
                <form onSubmit={this.handleSubmit}>
                <Step1 currentStep={this.state.currentStep} handleChange={this.handleChange} utskyring={this.state.utskyring_1}  flatarmal={this.state.flatarmal} />
                <Step2 currentStep={this.state.currentStep} handleChange={this.handleChange} thakkant={this.state.thakkant} utskyring={this.state.utskyring_2} efni={this.state.efni} bordklaedning={this.state.bordklaedning} efnibara={this.state.efnibara} thakkant={this.state.thakkant} einangrun={this.state.einangrun} uppstolad={this.state.uppstolad} halli={this.state.halli} flatarmal={this.state.flatarmal} />
                <Step3 currentStep={this.state.currentStep} handleChange={this.handleChange} thakkant={this.state.thakkant} utskyring={this.state.utskyring_3} efni={this.state.efni} bordklaedning={this.state.bordklaedning} efnibara={this.state.efnibara} thakkant={this.state.thakkant} einangrun={this.state.einangrun} uppstolad={this.state.uppstolad} halli={this.state.halli} flatarmal={this.state.flatarmal} />
                <Step4 currentStep={this.state.currentStep} handleChange={this.handleChange} thakkant={this.state.thakkant} utskyring={this.state.utskyring_4} efni={this.state.efni} bordklaedning={this.state.bordklaedning} efnibara={this.state.efnibara} thakkant={this.state.thakkant} einangrun={this.state.einangrun} uppstolad={this.state.uppstolad} halli={this.state.halli} flatarmal={this.state.flatarmal} />
                <Step5 currentStep={this.state.currentStep} handleChange={this.handleChange} thakkant={this.state.thakkant} utskyring={this.state.utskyring_5} efni={this.state.efni} bordklaedning={this.state.bordklaedning} efnibara={this.state.efnibara} thakkant={this.state.thakkant} einangrun={this.state.einangrun} uppstolad={this.state.uppstolad} halli={this.state.halli} flatarmal={this.state.flatarmal} />
                <Step6 currentStep={this.state.currentStep} handleChange={this.handleChange} thakkant={this.state.thakkant} utskyring={this.state.utskyring_6} efni={this.state.efni} bordklaedning={this.state.bordklaedning} efnibara={this.state.efnibara} thakkant={this.state.thakkant} einangrun={this.state.einangrun} uppstolad={this.state.uppstolad} halli={this.state.halli} flatarmal={this.state.flatarmal} />
                <Step7 currentStep={this.state.currentStep} 
                    utskyring={this.state.utskyring_7}
                    handleChange={this.handleChange} 
                    efni={this.state.efni} 
                    thakkant={this.state.thakkant}
                    efnibara={this.state.efnibara}
                    thakkant={this.state.thakkant} 
                    efnibara={this.state.efnibara} 
                    uppstolad={this.state.uppstolad} 
                    bordklaedning={this.state.bordklaedning} 
                    halli={this.state.halli} 
                    flatarmal={this.state.flatarmal} 
                    thakkantur_timbur={this.state.thakkantur_timbur}
                    thakkantur_al={this.state.thakkantur_al}
                    v_uppstolad={this.state.v_uppstolad}
                    litad_al_20={this.state.litad_al_20}
                    litad_al_10_20={this.state.litad_al_10_20}
                    alozink_20={this.state.alozink_20}
                    alozink_10_20={this.state.alozink_10_20}
                    verkbyrjun={this.state.verkbyrjun}
                    v_bordklaedning={this.state.v_bordklaedning}
                />
                <Step8 currentStep={this.state.currentStep} 
                    utskyring={this.state.utskyring_8}
                    handleChange={this.handleChange} 
                    efni={this.state.efni} 
                    thakkant={this.state.thakkant}
                    efnibara={this.state.efnibara}
                    thakkant={this.state.thakkant} 
                    efnibara={this.state.efnibara} 
                    uppstolad={this.state.uppstolad} 
                    bordklaedning={this.state.bordklaedning} 
                    halli={this.state.halli} 
                    flatarmal={this.state.flatarmal} 
                    thakkantur_timbur={this.state.thakkantur_timbur}
                    thakkantur_al={this.state.thakkantur_al}
                    v_uppstolad={this.state.v_uppstolad}
                    litad_al_20={this.state.litad_al_20}
                    litad_al_10_20={this.state.litad_al_10_20}
                    alozink_20={this.state.alozink_20}
                    alozink_10_20={this.state.alozink_10_20}
                    verkbyrjun={this.state.verkbyrjun}
                    v_bordklaedning={this.state.v_bordklaedning}
                />
                <Step9 currentStep={this.state.currentStep} 
                    utskyring={this.state.utskyring_8}
                    handleChange={this.handleChange} 
                    efni={this.state.efni} 
                    thakkant={this.state.thakkant}
                    efnibara={this.state.efnibara}
                    thakkant={this.state.thakkant} 
                    efnibara={this.state.efnibara} 
                    uppstolad={this.state.uppstolad} 
                    bordklaedning={this.state.bordklaedning} 
                    halli={this.state.halli} 
                    flatarmal={this.state.flatarmal} 
                    thakkantur_timbur={this.state.thakkantur_timbur}
                    thakkantur_al={this.state.thakkantur_al}
                    v_uppstolad={this.state.v_uppstolad}
                    litad_al_20={this.state.litad_al_20}
                    litad_al_10_20={this.state.litad_al_10_20}
                    alozink_20={this.state.alozink_20}
                    alozink_10_20={this.state.alozink_10_20}
                    verkbyrjun={this.state.verkbyrjun}
                    v_bordklaedning={this.state.v_bordklaedning}
                />
                {this.faformlegttilbodButton()}
                {this.previousButton()}
                {this.nextButton()}
                
                </form>
                </div>
            </React.Fragment>
        );
    }
    }

    function Step1(props) {
        if (props.currentStep !== 1) {
            return null
        } 
        var rows = [], i = 19, len = 600;
        while (++i <= len) rows.push(i);
        return(
            <div className="form-group">
                <div className="baran"></div>
                <h4>Hvað er flöturinn í m<sup>2</sup>?</h4>
                <select id="flatarmal" name="flatarmal" value={props.flatarmal} onChange={props.handleChange} onKeyPress={props.handleKeyPress} onKeyDown={props.handleKeyDown}>
                    {rows.map(row =>
                        <option key={row} value={row}>{row}</option>
                    )}
                </select>
                <div className="utskyring">{props.utskyring_1}</div>
            </div>
        );
    }

    function Step2(props) {
        if (props.currentStep !== 2) {
            return null
        } 
        return(
            <div className="form-group">
                <div className={"gradurstika" + props.halli}></div>
                <h4>Hver er hallinn á fletinum?</h4>
                <div className="toggle">
                    <input type="radio" name="halli" value="10_20" id="tiu" onChange={props.handleChange}/>
                    <label htmlFor="tiu" >10 - 20°</label>
                    <input type="radio" name="halli" value="20plus" id="tuttugu"  onChange={props.handleChange}/>
                    <label htmlFor="tuttugu">20 - 55°</label>                    
                </div>
                <div className="utskyring"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div>
            </div>
        );
    }
    
    function Step3(props) {
        if (props.currentStep !== 3) {
            return null
        } 
        return(
            <React.Fragment>
                <div className="form-group">
                    <div className={"baran " + props.bordklaedning }></div>
                    <h4>Viltu fá borðaklæðningu?</h4>
                    <div className="toggle">
                        <input type="radio" name="bordklaedning" value="medbordklaedningu" id="medbordklaedningu" onChange={props.handleChange}/>
                        <label htmlFor="medbordklaedningu">Já</label>
                        <input type="radio" name="bordklaedning" value="anbordklaedningu" id="anbordklaedningu" onChange={props.handleChange}/>
                        <label htmlFor="anbordklaedningu">Nei</label>
                    </div>  
                    <div className="utskyring"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div> 
                </div>
                
                
            </React.Fragment>
        );
    }

    
    function Step4(props) {
        if (props.currentStep !== 4) {
            return null
        } 
        return(
            <React.Fragment>
                <div className="form-group">
                    <div className={"baran " + props.bordklaedning + " " + props.efnibara}></div>
                    <h4>Hvernig efni á báran að vera úr?</h4>
                    <div className="toggle">
                        <input type="radio" name="efnibara" value="alozink" id="alozink" onChange={props.handleChange}/>
                        <label htmlFor="alozink">Aluzink</label>
                        <input type="radio" name="efnibara" value="litadal" id="litadal" onChange={props.handleChange}/>
                        <label htmlFor="litadal">Litað ál</label>
                    </div> 
                    <div className="utskyring"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div>  
                </div>
            </React.Fragment>
        );
    }

    function Step5(props) {
        if (props.currentStep !== 5) {
            return null
        } 
        return(
            <React.Fragment>
                <div className="form-group">
                    <div className={"baran " + props.efnibara + " " + props.uppstolad }></div>
                    <h4>Viltu fá þakið uppstólað?</h4>
                    <div className="toggle">
                        <input type="radio" name="uppstolad" value="uppstolad" id="jauppstolad" onChange={props.handleChange}/>
                        <label htmlFor="jauppstolad">Já</label>
                        <input type="radio" name="uppstolad" value="ekkiuppstolad" id="neiuppstolad" onChange={props.handleChange}/>
                        <label htmlFor="neiuppstolad">Nei</label>
                    </div>  
                    <div className="utskyring"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div> 
                </div>
            </React.Fragment>
        );
    }

    function Step6(props) {
        if (props.currentStep !== 6) {
            return null
        } 
        return(
            <React.Fragment>
                <div className="form-group">
                    <div className={"baran " + props.efnibara + " " + props.uppstolad + " " + props.thakkant}></div>
                    <h4>Viltu þakkant?</h4>
                    <div className="toggle">
                        <input type="radio" name="thakkant" value="ja" id="thakkantja" onChange={props.handleChange}/>
                        <label htmlFor="thakkantja" >Já</label> 
                        <input type="radio" name="thakkant" value="nei" id="thakkantnei" onChange={props.handleChange}/>
                        <label htmlFor="thakkantnei">Nei</label>
                    </div>   
                    <div className="utskyring"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div>
                </div>
            </React.Fragment>
        );
    }

    function Step7(props) {
        if (props.currentStep !== 7) {
            return null
        } 
        
        return(
            <React.Fragment>
                <div className="form-group">
                    <div className={"baran " + props.efnibara + " " + props.uppstolad + " " + props.thakkant}></div>
                    <h4>Úr hvaða efni?</h4>
                    <div className="toggle">
                        <input type="radio" name="efni" value="Ál" id="efnial" onChange={props.handleChange}/>
                        <label htmlFor="efnial">Ál</label> 
                        <input type="radio" name="efni" value="Timbur" id="efnitimbur" onChange={props.handleChange}/>
                        <label htmlFor="efnitimbur">Timbur</label>
                    </div>  
                    <div className="utskyring"><div dangerouslySetInnerHTML={{__html: props.utskyring}} /></div> 
                </div>
            </React.Fragment>
        );
    }

    function Step8(props) {
        if (props.currentStep !== 8) {
            return null
        } 

        let efni = props.efni;
        let thakkant = props.thakkant;
        let efnibara = props.efnibara;
        let uppstolad = props.uppstolad;
        let halli = props.halli;
        let flatarmal = props.flatarmal;
        let bordklaedning = props.bordklaedning;
        let verkbyrjun = props.verkbyrjun;
        let v_bordklaedning = props.v_bordklaedning;

        let vhalli;
        let vefnibara;
        let vefni;
        let vthakkant;
        let vuppstolad;

        let verduppstolad = 0;

        let thakkantur_timbur = props.thakkantur_timbur;
        let thakkantur_al = props.thakkantur_al;
        let v_uppstolad = props.v_uppstolad;
        let litad_al_20 = props.litad_al_20;
        let litad_al_10_20 = props.litad_al_10_20;
        let alozink_20 = props.alozink_20;
        let alozink_10_20 = props.alozink_10_20;
        let verdidmitt = 0;
        let fermverd = 0;
        let reiknadverd = 0;
        let t_bordklaedning = "";
        let verdbordklaedningu = 0;
        let vthakkantfm = 0;
        let thakkantverd = 0;

        if (halli == "20plus") {
            vhalli = "20+°";
        } else {
            vhalli = "10-20°";  
        }

        if (efni == "Ál") {
            vefni = " áli";            
        } else if (efni == "Timbur") {
            vefni = " timbri";

        } else {
            vefni = " ";
        }
        
        if (efnibara == "alozink") {
            vefnibara = "Aluzink";
            if (halli == "20plus") { 
                fermverd = alozink_20;
            } else { 
                fermverd = alozink_10_20;    
            }
        } else {
            vefnibara = "Litað ál";
            if (halli == "20plus") { 
                fermverd = litad_al_20;
            } else { 
                fermverd = litad_al_10_20;    
            }  
        }

        if (uppstolad == "uppstolad") {
            vuppstolad = "Þakið á að vera uppstólað";
            verduppstolad = v_uppstolad * flatarmal;
        } else {
            vuppstolad = "";
            verduppstolad = 0;
        }

        if (bordklaedning == "medbordklaedningu") {
            t_bordklaedning = "Þakið á að vera með borðaklæðningu";
            verdbordklaedningu = v_bordklaedning * flatarmal;
        } else {
            t_bordklaedning = "";
            verdbordklaedningu = 0;
        }

        if (thakkant == "ja") {
            vthakkant = "Þakkkant úr " + vefni;
            vthakkantfm = flatarmal * 0.25;
            if (efni == "Ál") {
                thakkantverd = thakkantur_al * vthakkantfm; 
            } else {
                thakkantverd = thakkantur_timbur * vthakkantfm;
            }
        } else {
            vthakkant = "";  
            vthakkantfm = 0;
            thakkantverd = 0;
        }

        reiknadverd = fermverd * flatarmal;
        const reiknaverd = verduppstolad + reiknadverd + verdbordklaedningu + thakkantverd;
        return(
            <React.Fragment>
                <div>
                    <h3>Þú hefur valið</h3>
                    Flatarmál: {flatarmal} m2 með {vhalli} halla<br/>
                    Efni á báru er {vefnibara}<br/>
                    {vthakkant}<br/>
                    {vuppstolad}<br/>
                    {t_bordklaedning}
                    <div className={"baran " + props.efnibara + " " + props.uppstolad + " " + props.thakkant}></div>
                    <h4 className="verd">Verðáætlun:</h4>
                    <NumberFormat value={reiknaverd} className="verd" displayType={'text'} thousandSeparator="." decimalSeparator=","
                        renderText={(value, props) => <div {...props}>{value} kr.</div>}
                    />
                    <strong>Áætluð verkbyrjun</strong><br/>
                    {verkbyrjun}<br/><br/>

                </div>
            </React.Fragment>
        );
    }

    function Step9(props) {
        if (props.currentStep !== 9) {
            return null
        } 
        let efni = props.efni;
        let thakkant = props.thakkant;
        let efnibara = props.efnibara;
        let uppstolad = props.uppstolad;
        let halli = props.halli;
        let flatarmal = props.flatarmal;
        let bordklaedning = props.bordklaedning;
        let verkbyrjun = props.verkbyrjun;
        let v_bordklaedning = props.v_bordklaedning;
        let vthakkantfm = 0;

        let vhalli;
        let vefnibara;
        let vefni;
        let vthakkant;
        let vuppstolad;

        let verduppstolad = 0; 

        let thakkantur_timbur = props.thakkantur_timbur;
        let thakkantur_al = props.thakkantur_al;
        let v_uppstolad = props.v_uppstolad;
        let litad_al_20 = props.litad_al_20;
        let litad_al_10_20 = props.litad_al_10_20;
        let alozink_20 = props.alozink_20;
        let alozink_10_20 = props.alozink_10_20;
        let verdidmitt = 0;
        let fermverd = 0;
        let reiknadverd = 0;
        let t_bordklaedning = "";
        let verdbordklaedningu = 0;
        let thakkantverd = 0;

        if (halli == "20plus") {
            vhalli = "20+°";
        } else {
            vhalli = "10-20°";  
        }

        if (efni == "Ál") {
            vefni = " áli";            
        } else if (efni == "Timbur") {
            vefni = " timbri";

        } else {
            vefni = " ";
        }
        
        if (efnibara == "alozink") {
            vefnibara = "Aluzink";
            if (halli == "20plus") { 
                fermverd = alozink_20;
            } else { 
                fermverd = alozink_10_20;    
            }
        } else {
            vefnibara = "Litað ál";
            if (halli == "20plus") { 
                fermverd = litad_al_20;
            } else { 
                fermverd = litad_al_10_20;    
            }  
        }

        if (uppstolad == "uppstolad") {
            vuppstolad = "Þakið á að vera uppstólað";
            verduppstolad = v_uppstolad * flatarmal;
        } else {
            vuppstolad = "";
            verduppstolad = 0;
        }

        if (bordklaedning == "medbordklaedningu") {
            t_bordklaedning = "Þakið á að vera með borðaklæðningu";
            verdbordklaedningu = v_bordklaedning * flatarmal;
        } else {
            t_bordklaedning = "";
            verdbordklaedningu = 0;
        }

        if (thakkant == "ja") {
            vthakkant = "Þakkkant úr " + vefni;
            vthakkantfm = flatarmal * 0.25;
            if (efni == "Ál") {
                thakkantverd = thakkantur_al * vthakkantfm; 
            } else {
                thakkantverd = thakkantur_timbur * vthakkantfm;
            }
        } else {
            vthakkant = "";  
            vthakkantfm = 0;
            thakkantverd = 0;
        }

        reiknadverd = fermverd * flatarmal;
        const reiknaverd = verduppstolad + reiknadverd + verdbordklaedningu + thakkantverd;

        return(
            <React.Fragment>
                <div>
                <h3>Fá formlegt tilboð</h3>
                <form method="post" action="https://content.thakco.is/ajax/createapostbara.php" onSubmit={props.handleValidation}>

                    <input type="text" name="Nafn" className="inputform" placeholder="Nafn" required />
                    <input type="email" name="Netfang" className="inputform" placeholder="Netfang" required />
                    <input type="text" name="Simi" className="inputform" placeholder="Símanúmer" required />
                    <input type="text" name="kennitala" className="inputform" placeholder="Kennitala" required />
                    <input type="text" name="heimilisfang" className="inputform" placeholder="Heimilisfang" required />
                    <input type="text" name="postcode" className="inputform postcode" placeholder="Póstnr." required />
                    <input type="text" name="stadur" className="inputform stadur" placeholder="Bær/Borg" required />

                    <textarea name="athugasemdir" className="athugasemdir" placeholder="Athugasemdir"></textarea>

                    <input type="hidden" name="heildarverd" value={reiknaverd} />
                    <input type="hidden" name="v_flatamal" value={flatarmal} />
                    <input type="hidden" name="vhalli" value={vhalli} />
                    <input type="hidden" name="vefni" value={vefni} />
                    <input type="hidden" name="vefnibara" value={vefnibara} />
                    <input type="hidden" name="vuppstolad" value={vuppstolad} />
                    <input type="hidden" name="vthakkant" value={vthakkant} />
                    <input type="hidden" name="uppstolad" value={uppstolad} />
                    <input type="hidden" name="t_bordklaedning" value={t_bordklaedning} />
                    
                    <input type="submit" value="Senda" className="next" />
                    
                </form>
                </div>
            </React.Fragment>
        );
    }    
    
export default Bara;