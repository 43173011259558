import React from 'react';
import { BrowserRouter, Switch, Route, NavLink } from 'react-router-dom';

import Reiknadu from './Views/Reiknadu';
import Bara from './Views/Bara';

import Takk from './Views/Takk';
import ValMynd from './Views/ValMynd';
import Stadfest from './Views/Stadfest'

function App() {
    return (
        <div className="App">
        <BrowserRouter>
        <div>
            <Switch>
                <Route exact path="/" component={Reiknadu} />
                <Route exact path="/val" component={ValMynd} />
                <Route path="/reiknadu" component={Reiknadu} />
                <Route path="/bara" component={Bara} />
                <Route path="/takkfyrir" component={Takk} />
                <Route path="/stadfestpontun" component={Stadfest} />
            </Switch>
        </div>
        </BrowserRouter>

        </div>
    );
}

export default App;