import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export class ValMynd extends Component {

    render() {
        return (
            <div className="wrapperReikn">
                <main className="textalignc">
                    <h4>Í hvað viltu verðáætlun</h4>
                    <Link to="reiknadu" className="next">Þakpappa</Link><br/><br/>eða<br/><br/>
                    <Link to="bara" className="next">Þakbáru</Link>
                </main>
            </div>
        );
    }
}

export default ValMynd;
